import Button from 'common/Button';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import Image from 'common/Image';
import ProductPriceSpider from 'components/Products/ProductPriceSpider';
import { navigateToPage } from 'utils/functions';
import { ELEMENT_PRODUCT_IMAGE_WRAPPER, MEN_CATEGORY } from 'utils/constants';
import PlainImage from 'common/Image/PlainImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import BazaarVoice from 'common/BazaarVoice';
import classNames from 'classnames';

import { ProductCardProps } from './models';

import './ProductCard.scss';

const ProductCard: FC<ProductCardProps> = ({
  productTitle,
  productTitleWithSize,
  productSize,
  url,
  productImage,
  localProductImage,
  learnMoreLabel,
  productEAN,
  productTitleShort,
  variantProductCards,
  currentCategory,
}) => {
  const productName = productTitle || productTitleWithSize;
  const [plainImageClassName, setPlainImageClassName] = useState('');
  const [localImage, setLocalImage] = useState(localProductImage);
  const [product, setProduct] = useState({
    size: productSize,
    ean: productEAN,
    url,
    image: productImage,
  });

  const productImageUrl = `${product.url}${ELEMENT_PRODUCT_IMAGE_WRAPPER}`;

  const handleNavigation = () => navigateToPage(productImageUrl);

  const updateSelectedVariant = (variant) => {
    const previousUAN = product.ean;
    const { localProductImage: dynamicImage } = variantProductCards?.find(
      (item) => item.url === variant.url
    );
    setPlainImageClassName('plain-image--variant');
    setLocalImage(dynamicImage);
    setProduct(variant);
    const curentVariant = document.querySelectorAll<HTMLElement>(`[data-ps-sku="${previousUAN}"]`);
    curentVariant.forEach((element) => {
      element.dataset.psSku = variant.ean;
    });
  };

  return (
    <div className="product-card" data-testid="product-card">
      <Link to={productImageUrl} className="product-card__link">
        <div className="product-card__img" data-testid="product-image">
          {localImage ? <Image localImage={localImage} alt={productName!} /> : null}
          {!localImage && product.image ? (
            <PlainImage
              lazyLoad
              src={product.image}
              alt={productName!}
              className={plainImageClassName}
            />
          ) : null}
        </div>
        <DangerouslySetInnerHtml
          element="h2"
          html={productName || productTitleShort}
          className="product-card__title"
        />
        <DangerouslySetInnerHtml
          element="p"
          html={product.size}
          className="product-card__description"
        />
      </Link>
      {variantProductCards ? (
        <div className="product-card__variants">
          {variantProductCards.map((variant) => (
            <div
              onClick={() => updateSelectedVariant(variant)}
              className={`product-card__variants-size ${
                variant.url === product.url ? 'product-card__variants-size--active' : ''
              }`}
              role="button"
              tabIndex={0}
            >
              {variant.size}
            </div>
          ))}
        </div>
      ) : null}
      <BazaarVoice eanCode={product.ean} inlineRating productURL={product.url} />
      <div
        className={classNames('product-card__btn-wrapper', {
          'banner--men': currentCategory === MEN_CATEGORY,
        })}
        data-testid="product-card-buttons"
      >
        <Button ariaLabel={learnMoreLabel} onClick={() => handleNavigation()} isReversed>
          {learnMoreLabel}
        </Button>
        <ProductPriceSpider {...{ productEAN, currentCategory }} />
      </div>
    </div>
  );
};

export default ProductCard;
